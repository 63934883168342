/* Für Safari notwendig, damit die Navigations-Buttons für die Monate sichtbar sind */
.react-datepicker__navigation-icon--next, .react-datepicker__navigation-icon--previous {
    white-space: nowrap;
}

/* Entfernt doppelten Rand, wenn DatePicker als DropDownMenu gerendert wird */
.dropdown-menu .react-datepicker {
       border: none;
   }

.react-datepicker-popper {
    z-index: 3;
}