.tags {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   gap: 0.5rem;
   margin-bottom: 0.25rem;
   min-height: 38px;
}

small.image-copyright {
   font-size: 8px;
}

.lego-set-image {
   display: flex;
   align-items: center;
   padding: 0.5rem;
   background-color: #373737;
   border-top-left-radius: 0.25rem;
   border-top-right-radius: 0.25rem;

   & .image-sidebar {
      display: flex;
      flex-direction: column;
      align-self: start;
      gap: 0.5rem;
      width: 32px;

      & img {
         opacity: 0.7;

         &:hover, &:active {
            opacity: 1;
            transition: opacity 0.15s ease-in-out;
         }
      }
   }
}

