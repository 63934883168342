.lineWithText {
   display: flex;
   flex-direction: row;
   align-items: center;

   & .line {
      flex: 1;
      height: 1px;
      background-color: var(--bs-secondary);
      margin-left: 1rem;
      margin-right: 1rem;
   }
}