/* Weiß auf 87% transparenz "abdunkeln", damit es wegen des dunklen Hintergrunds besser lesbar ist  */
body {
   // Gets an padding for safe area (e.g. touch area of iPhone)
   //    We need to subtract 1rem since the footer already has 1rem padding at the bottom
   --safe-area-inset-bottom: calc(env(safe-area-inset-bottom) - 1rem);

   font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
      Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.debug, .debug-1 {
   outline: red solid 1px !important;
}
.debug-2 {
   outline: blue solid 1px !important;
}
.debug-3 {
   outline: green solid 1px !important;
}
.debug-4 {
   outline: yellow solid 1px !important;
}

.debug-5 {
   outline: purple solid 1px !important;
}

.colored-value.good {
   color: #0c8465;
}

.colored-value.bad {
   color: #b14135;
}

.title-button::after {
   display: none;

}

.title-button {
   border: none;
}

// react-medium-image-zoom

[data-rmiz-modal-overlay="hidden"] {
   background-color: rgba(32, 32, 32, 0.8);
}
[data-rmiz-modal-overlay="visible"] {
   background-color: rgba(32, 32, 32, 0.8);
}

[data-rmiz-modal-overlay], [data-rmiz-modal-img] {
   transition-duration: 0.6s;
   transition-timing-function: linear;
}

[data-rmiz-btn-open] {
   cursor: zoom-in !important;
}
[data-rmiz-btn-close] {
   cursor: zoom-out !important;
}

/* Generelle Struktur der Seite */
#page-container {
   position: relative;
   min-height: 100vh;
   display: flex;
   flex-direction: column;

   & #content {
      padding-bottom: 2rem;
      flex-grow: 1;

      & .container {
         padding-top: 1rem;
      }

      &.mobile {
         padding-top: 3.75rem;
         // Same as height of #mobile-footer
         padding-bottom: calc(5.5rem + var(--safe-area-inset-bottom));
      }
   }
}
#footer {
   width: 100%;
   background-color: var(--bs-secondary);
   display: block;

   & .container {
      background-color: var(--bs-secondary);
      padding-top: 1rem;
      padding-bottom: 1rem;
   }
}

#mobile-footer {
   background-color: var(--bs-secondary);
   flex-direction: column;
   align-items: stretch;
   padding: 0;

   & #safe-area-bottom {
      height: var(--safe-area-inset-bottom);
      visibility: hidden;

      &.show-safe-areas {
         background-color: red;
         text-align: center;
         visibility: visible;
      }
   }

   & #bottom-mobile-footer {
      height: 3rem;
      margin: 1rem 0;
   }

   & #bottom-tab-bar {
      display: flex;
      flex: 1;
      padding: 1rem 0;

      & > a {
         flex: 1;
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: center;
         border: none;
         background-color: var(--bs-secondary);
         height: 3rem;
      }

      & a.btn {
         &.active, &:active, &:hover, &:focus {
            background-color: var(--bs-secondary);
            border: 0 none;
            box-shadow: none;
         }
      }

      & a.btn.active {
         & span.nav-icon {
            flex: 1;
            border-radius: 1.25rem;
            padding: 0.25rem;
            background-color: var(--bs-success);

         }
      }
   }
}

.imprint {
   & p {
      text-align: justify;
   }

   & p, & h1, & h2, & h3, & h4, & h5, & h6 {
      hyphens: auto;
   }
}



.btn-no-padding {
   padding: 0;
}

.button-panel {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   gap: 0.5rem;
   padding: 0 0.5rem;

   &.form-footer {
      border-top: 1px solid var(--bs-secondary);
      background-color: var(--bs-gray-dark);
      margin-top: 2rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
   }
}

.button-panel-mobile {
   display: flex;
   flex-direction: column;
   flex: 1;
   gap: 0.5rem;
   padding: 0;
   margin: 1rem 0;
}

.modal-footer .button-panel-mobile {
   flex-direction: column-reverse;
}

.dropdown-menu.scrollable,
.dropdown-menu .scrollable {
   max-height: 13rem;
   overflow-y: scroll;
}
.dropdown-menu.scrollable-lg,
.dropdown-menu .scrollable-lg {
   max-height: 420px;
   overflow-y: scroll;
}

.scrollable-top-indicator {
   z-index: 1001;
   height: 3rem;
   background: rgb(34, 34, 34);
   background: linear-gradient(0deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 1) 55%);
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
}

.scrollable-bottom-indicator {
   z-index: 1001;
   height: 3rem;
   background: rgb(34, 34, 34);
   background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, rgba(34, 34, 34, 1) 55%);
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
}

.dropdown-filter {
   &.dropdown {
      flex: 1;
   }

   & .dropdown-toggle {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & span {
         overflow: hidden;
         text-overflow: ellipsis;
      }

      &::after {
         content: none;
      }
   }
}

.badge-lg {
   font-size: 100%;
}

span.react-loading-skeleton {
   border-radius: 2px;
   margin: 2px;
}

.chart-loader {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
}

.loading-skeleton {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 22rem;
   width: 100%;
}

.brick-loader > div {
   height: 6rem;
   width: 6rem;
}

.list-group a.list-group-item-action.active {
   border: 1px solid var(--bs-secondary);
   background-color: var(--bs-gray-dark);
}

/* Toast-Messages */
.Toastify__toast-theme--dark {
   background: var(--bs-secondary);
   color: var(--bs-body-color);
}

.cursor-pointer {
   cursor: pointer;
}

.vertical-align-text-bottom {
   vertical-align: text-bottom;
}

.btn-link.danger {
   color: var(--bs-danger);
}

.btn-link.danger:hover {
   filter: brightness(60%);
}

.btn-link.link {
   padding: 0;
}

/* line-clamp */
.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4,
.line-clamp-5 {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   overflow: hidden;
}

.line-clamp-1 {
   -webkit-line-clamp: 1;
}
.line-clamp-2 {
   -webkit-line-clamp: 2;
}
.line-clamp-3 {
   -webkit-line-clamp: 3;
}
.line-clamp-4 {
   -webkit-line-clamp: 4;
}
.line-clamp-5 {
   -webkit-line-clamp: 5;
}

blockquote {
   border-left: 5px solid var(--bs-secondary);
   margin: 1.5rem 0.625rem;
   padding: 0.5rem 0.625rem;
}

fieldset {
   background-color: rgba(var(--bs-primary-rgb), 0.2);
   border: 1px var(--bs-secondary) solid;
   border-radius: 0.25rem;
   margin-top: 1rem;
   padding: 1rem;

   & legend {
      float: none;
      font-size: 1.25rem;
      background-color: var(--bs-primary);
      margin-left: 1rem;
      padding: 0 1rem;
      width: auto;
   }
}

a.no-styling {
   color: inherit;
   text-decoration: inherit;
}
a.no-underline {
   text-decoration: inherit;
}

.overflow-ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.card-clickable {
   color: var(--bs-body-color);
   background-color: var(--bs-gray-dark);
}

.card-clickable:hover  {
   color: var(--bs-body-color);
   background-color: var(--bs-gray-dark);
   border-color: var(--bs-secondary);
   -webkit-box-shadow: 0 0 0.75rem 0 var(--bs-secondary);
   box-shadow: 0 0 0.75rem 0 var(--bs-secondary);
   cursor: pointer;

   &.mobile {
      -webkit-box-shadow: 0 0 0.25rem 0 var(--bs-secondary);
      box-shadow: 0 0 0.25rem 0 var(--bs-secondary);
   }
}

.card-clickable:active, .card-clickable.active, .card-clickable:focus, .card-clickable.btn:first-child:active {
   color: var(--bs-body-color);
   border-color: rgba(0,0,0,.125);
   background-color: var(--bs-gray-dark) !important;
}

/* table */
.table.flat > thead > tr > th {
   background-color: var(--bs-dark);
   color: var(--bs-gray);
   font-weight: normal;
   border: none;
}

.table.flat > tbody > tr > td {
   padding-top: 1rem;
   padding-bottom: 1rem;
   border-top: none;
   border-bottom: 1px solid var(--bs-secondary);
}

.inner-table td,
.inner-table th {
   padding: 0 10px;
}

/* Log Messages */
.logMessage-debug, .logMessage-info, .logMessage-warn, .logMessage-error {
   border: none;
}

.logMessage-debug, .logMessage-debug:hover, .logMessage-debug:active {
   border-left: 0.5rem solid var(--bs-primary);
}

.logMessage-info, .logMessage-info:hover, .logMessage-info:active {
   border-left: 0.5rem solid var(--bs-secondary);
}

.logMessage-warn, .logMessage-warn:hover, .logMessage-warn:active {
   border-left: 0.5rem solid var(--bs-warning);
}

.logMessage-error, .logMessage-error:hover, .logMessage-error:active {
   border-left: 0.5rem solid var(--bs-danger);
}

/* Import-Status */

.import-status-new, .import-status-duplicate, .import-status-success, .import-status-error {
   border: none;
}

.import-status-new {
   border-left: 0.5rem solid var(--bs-secondary);
}

.import-status-duplicate {
   border-left: 0.5rem solid var(--bs-warning);
}

.import-status-success {
   border-left: 0.5rem solid var(--bs-success);
}

.import-status-error {
   border-left: 0.5rem solid var(--bs-danger);
}
