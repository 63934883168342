.queryBuilder {
    width: 100%;

    & .ruleGroup {
        background-color: rgba(var(--bs-primary-rgb), 0.2);
        border: 1px var(--bs-secondary) solid;
        padding: 1rem;

        /* Fixes input layout for date input */
        & .rule {
            display: flex;
        }

        & .form-control {
            width: auto;
        }
    }
}
