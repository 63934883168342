.icon-property-group {
  display: flex;
  padding-top: 0.5rem;

  & > span {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    border: 0 solid var(--bs-secondary);
    border-right-width: 1px;

    &:last-child {
      border-right-width: 0;
    }

    & > .h5 {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
  }
}