.lego-set-grid-container {
   // padding: 0 0.5rem;


}
.displayModeBtnGrp {
   display: flex;
   justify-content: flex-end;
}

.inventory-table {
   margin-left: 1rem;
   margin-right: 1rem;

   & img {
      display: block;
      max-width: 6.25rem;
      max-height: 3.125rem;
      width: auto;
      height: auto;
   }
}

.spacer-line {
   flex: 1;
   height: 1px;
   background-color: var(--bs-secondary);
}

.grid-filter {
   max-height: 0;
   overflow: hidden;
   transition: max-height 0.15s ease-out;

   &.visible {
      max-height: 150px;
      overflow: visible;
      transition: max-height 0.25s ease-in;
   }
}