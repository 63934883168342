.changelog  {

   & h2 {
      margin-top: 4rem;
   }

   & h4 {
      margin-top: 1rem;
      margin-left: 1rem;
   }

   & ul {
      margin-left: 1.5rem;
   }

}