.card.mobile {
   padding: 0;
   text-align: start;

   /*&:active {
      background-color: #373737;
   }*/
}


.card-legoset {
   margin-bottom: 0.625rem;
   padding: 0;
   text-align: start;

   & .card-image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #373737;
      padding: 0.25rem;
      height: 10rem;

      &::before {
         display: inline-block;
         height: 100%;
         vertical-align: middle;
      }

      & .category-overlay {
         position: absolute;
         top: 0;
         left: 0;
         padding: 0.25rem 0.5rem;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         max-width: 90%;
         background-color: rgba(var(--bs-primary-rgb), 0.85)
      }
   }

   & .card-body {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      height: 9rem;

      &.inventory-type-generic {
         height: 12rem;
      }

      & a.icon {
         margin: 0.25rem;
      }

      & .card-body-price {
         display: flex;
         align-items: center;
         margin-bottom: 0.25rem;

         & .colored-value {
            margin-right: 0.5rem;
            margin-bottom: 0;
         }
      }

      & .card-body-price-sub {
         display: flex;
         align-items: center;
         margin-bottom: 0.5rem;
      }
   }
}
