.brand-logo {
   border-radius: 5px;
}

.nav-item .btn-primary,
.dropdown .btn-primary {
   border-color: #28415b;
}

.nav-item .btn-group > .btn-primary:not(:last-child):not(.dropdown-toggle) {
   border-right-color: transparent;
}

.nav-item .btn-group > .btn-primary:not(:first-child) {
   border-left-color: transparent;
}

.navbar-nav {
   display: list-item;
}

@media (min-width: 992px) {
   .navbar-left > div {
      margin-right: 0.3125rem;
      margin-left: 0.3125rem;
   }
   .navbar-nav {
      display: inline-flex;
   }
}

#search-form {
   margin-right: 0.625rem;
}
