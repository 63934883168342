.blog-post {
   // Restored original style for cards on blogpost
   border: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.blog-post-draft,
.blog-post-comment-reported {
   background: #53442b;
}

.blog-post-deleted,
.blog-post-comment-deleted {
   background: #513532;
}

.blog-post-header,
.blog-post-header > div,
.blog-post-header > div > p {
   display: inline;
}

.reaction-tile {
   display: flex;
   align-items: center;
   padding: 0.5rem 0.5rem;
   border-right-color: #3030307d;
   border-right-style: solid;
   border-right-width: 2px;

   & .select-reaction:hover path {
      fill: #ffffff !important;
   }
}
