.popover {
   border-color: var(--bs-gray);
   -webkit-box-shadow: 0 0 0.75rem 0 var(--bs-secondary);
   box-shadow: 0 0 0.75rem 0 var(--bs-secondary);
}

.date-picker-popover .arrow::before,
.date-picker-popover .arrow::after {
   border-bottom-color: var(--bs-secondary);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before {
   border-top-color: var(--bs-gray);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after {
   border-top-color: var(--bs-gray-dark);
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before {
   border-right-color: var(--bs-gray);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^='right'] > .arrow::after {
   border-right-color: var(--bs-gray-dark);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
   border-bottom-color: var(--bs-gray);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
   border-bottom-color: var(--bs-gray-dark);
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before {
   border-left-color: var(--bs-gray);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^='left'] > .arrow::after {
   border-left-color: var(--bs-gray-dark);
}
