:root {
    --bs-body-color: #ffffffde;
    --bs-gutter-x: 0.5rem;
}

/* Layout */

.row {
    --bs-gutter-x: 0.5rem;
}

/* Table */
.table {
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-hover-color: var(--bs-body-color);
    color: var(--bs-body-color);
}

/* Tabs */
.nav-tabs .nav-link.active {
    background-color: #303030; /* dark_gray */
}

.tab-content .tab-pane {
    padding: 10px;
}

/* Input */
input:disabled,
input:disabled + label {
    cursor: not-allowed;
}

/* Buttons */
.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    cursor: not-allowed;
}

.form-control:disabled, .form-control[readonly] {
    background-color: var(--bs-gray-400);
    color: var(--bs-gray)
}

/* Cards */
.card {
    border: none;
}


/* Accordion as Card */
.accordion-item.card .accordion-button {
    color: inherit;
    background: var(--bs-secondary);
    box-shadow: none;
    cursor: pointer;
}

.accordion-item.card:first-of-type .accordion-button {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item.card .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.accordion-item.card .accordion-button.accordion-hide-indicator::after {
    content: none;
}

/* DropDowns */

.dropdown-menu-end {
    right: 0;
    left: auto;
}

/* Modals */

.model-header {
    padding: 0.5rem 1rem;
}